
function App() {
  return (
    <div className="App">
      <div>hello word</div>
    </div>
  );
}

export default App;
